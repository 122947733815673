/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  HERC165Storage,
  HERC165StorageInterface,
} from "../../../../contracts/dependant/erc165/HERC165Storage";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
] as const;

const _bytecode =
  "0x6080604052348015600f57600080fd5b50601e6301ffc9a760e01b6022565b60a4565b6001600160e01b03198082169003607f5760405162461bcd60e51b815260206004820152601c60248201527f4552433136353a20696e76616c696420696e7465726661636520696400000000604482015260640160405180910390fd5b6001600160e01b0319166000908152602081905260409020805460ff19166001179055565b603f8060b16000396000f3fe6080604052600080fdfea2646970667358221220c849843636e3ebef837df2e6e98ef63785b9b8988d512e27760afdf12ba7550c64736f6c63430008110033";

type HERC165StorageConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: HERC165StorageConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class HERC165Storage__factory extends ContractFactory {
  constructor(...args: HERC165StorageConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<HERC165Storage> {
    return super.deploy(overrides || {}) as Promise<HERC165Storage>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): HERC165Storage {
    return super.attach(address) as HERC165Storage;
  }
  override connect(signer: Signer): HERC165Storage__factory {
    return super.connect(signer) as HERC165Storage__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): HERC165StorageInterface {
    return new utils.Interface(_abi) as HERC165StorageInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): HERC165Storage {
    return new Contract(address, _abi, signerOrProvider) as HERC165Storage;
  }
}
