/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  GenericDeputyCenterStorage,
  GenericDeputyCenterStorageInterface,
} from "../../../../contracts/dependant/deputyCenter/GenericDeputyCenterStorage";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "nameService_",
        type: "address",
      },
      {
        internalType: "address",
        name: "owner_",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
] as const;

const _bytecode =
  "0x6080604052348015600f57600080fd5b5060405160ef38038060ef833981016040819052602a916075565b600080546001600160a01b039283166001600160a01b0319918216179091556002805492909316911617905560a3565b80516001600160a01b0381168114607057600080fd5b919050565b60008060408385031215608757600080fd5b608e83605a565b9150609a60208401605a565b90509250929050565b603f8060b06000396000f3fe6080604052600080fdfea264697066735822122029f2dd9683f67a590f72da217d7ceec17c95744f55b65b71f80d5dfac9c1128564736f6c63430008110033";

type GenericDeputyCenterStorageConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: GenericDeputyCenterStorageConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class GenericDeputyCenterStorage__factory extends ContractFactory {
  constructor(...args: GenericDeputyCenterStorageConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    nameService_: PromiseOrValue<string>,
    owner_: PromiseOrValue<string>,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<GenericDeputyCenterStorage> {
    return super.deploy(
      nameService_,
      owner_,
      overrides || {}
    ) as Promise<GenericDeputyCenterStorage>;
  }
  override getDeployTransaction(
    nameService_: PromiseOrValue<string>,
    owner_: PromiseOrValue<string>,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(nameService_, owner_, overrides || {});
  }
  override attach(address: string): GenericDeputyCenterStorage {
    return super.attach(address) as GenericDeputyCenterStorage;
  }
  override connect(signer: Signer): GenericDeputyCenterStorage__factory {
    return super.connect(signer) as GenericDeputyCenterStorage__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): GenericDeputyCenterStorageInterface {
    return new utils.Interface(_abi) as GenericDeputyCenterStorageInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): GenericDeputyCenterStorage {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as GenericDeputyCenterStorage;
  }
}
