/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  HERC1155Storage,
  HERC1155StorageInterface,
} from "../../../../contracts/dependant/erc1155/HERC1155Storage";

const _abi = [
  {
    inputs: [
      {
        internalType: "string",
        name: "uri_",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
] as const;

const _bytecode =
  "0x608060405234801561001057600080fd5b5060405161037538038061037583398101604081905261002f91610117565b61003f6301ffc9a760e01b61006e565b610048816100f1565b610058636cdb3d1360e11b61006e565b6100686303a24d0760e21b61006e565b50610328565b6001600160e01b031980821690036100cc5760405162461bcd60e51b815260206004820152601c60248201527f4552433136353a20696e76616c696420696e7465726661636520696400000000604482015260640160405180910390fd5b6001600160e01b0319166000908152602081905260409020805460ff19166001179055565b60036100fd8282610269565b5050565b634e487b7160e01b600052604160045260246000fd5b6000602080838503121561012a57600080fd5b82516001600160401b038082111561014157600080fd5b818501915085601f83011261015557600080fd5b81518181111561016757610167610101565b604051601f8201601f19908116603f0116810190838211818310171561018f5761018f610101565b8160405282815288868487010111156101a757600080fd5b600093505b828410156101c957848401860151818501870152928501926101ac565b600086848301015280965050505050505092915050565b600181811c908216806101f457607f821691505b60208210810361021457634e487b7160e01b600052602260045260246000fd5b50919050565b601f82111561026457600081815260208120601f850160051c810160208610156102415750805b601f850160051c820191505b818110156102605782815560010161024d565b5050505b505050565b81516001600160401b0381111561028257610282610101565b6102968161029084546101e0565b8461021a565b602080601f8311600181146102cb57600084156102b35750858301515b600019600386901b1c1916600185901b178555610260565b600085815260208120601f198616915b828110156102fa578886015182559484019460019091019084016102db565b50858210156103185787850151600019600388901b60f8161c191681555b5050505050600190811b01905550565b603f806103366000396000f3fe6080604052600080fdfea2646970667358221220e875ea1b0d2ac6a6852c546a6decdeed5ca3b33db0162b569d4cec2f3ed409a464736f6c63430008110033";

type HERC1155StorageConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: HERC1155StorageConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class HERC1155Storage__factory extends ContractFactory {
  constructor(...args: HERC1155StorageConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    uri_: PromiseOrValue<string>,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<HERC1155Storage> {
    return super.deploy(uri_, overrides || {}) as Promise<HERC1155Storage>;
  }
  override getDeployTransaction(
    uri_: PromiseOrValue<string>,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(uri_, overrides || {});
  }
  override attach(address: string): HERC1155Storage {
    return super.attach(address) as HERC1155Storage;
  }
  override connect(signer: Signer): HERC1155Storage__factory {
    return super.connect(signer) as HERC1155Storage__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): HERC1155StorageInterface {
    return new utils.Interface(_abi) as HERC1155StorageInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): HERC1155Storage {
    return new Contract(address, _abi, signerOrProvider) as HERC1155Storage;
  }
}
