/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  AccessControlRefStorage,
  AccessControlRefStorageInterface,
} from "../../../../contracts/dependant/accessControlRef/AccessControlRefStorage";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "accessControl_",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
] as const;

const _bytecode =
  "0x6080604052348015600f57600080fd5b5060405160c838038060c8833981016040819052602a91604e565b600080546001600160a01b0319166001600160a01b0392909216919091179055607c565b600060208284031215605f57600080fd5b81516001600160a01b0381168114607557600080fd5b9392505050565b603f8060896000396000f3fe6080604052600080fdfea2646970667358221220fc43158fc1dc57aa4f755f24fde0fb5ab1f1687741a938a18573f97902ca2a7364736f6c63430008110033";

type AccessControlRefStorageConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: AccessControlRefStorageConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class AccessControlRefStorage__factory extends ContractFactory {
  constructor(...args: AccessControlRefStorageConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    accessControl_: PromiseOrValue<string>,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<AccessControlRefStorage> {
    return super.deploy(
      accessControl_,
      overrides || {}
    ) as Promise<AccessControlRefStorage>;
  }
  override getDeployTransaction(
    accessControl_: PromiseOrValue<string>,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(accessControl_, overrides || {});
  }
  override attach(address: string): AccessControlRefStorage {
    return super.attach(address) as AccessControlRefStorage;
  }
  override connect(signer: Signer): AccessControlRefStorage__factory {
    return super.connect(signer) as AccessControlRefStorage__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): AccessControlRefStorageInterface {
    return new utils.Interface(_abi) as AccessControlRefStorageInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): AccessControlRefStorage {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as AccessControlRefStorage;
  }
}
