/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  Base,
  BaseInterface,
} from "../../../../contracts/dependant/proxy/Base";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
] as const;

const _bytecode =
  "0x6080604052348015600f57600080fd5b50603f80601d6000396000f3fe6080604052600080fdfea2646970667358221220e351df50e0eb211e8c375844b9ab3b66cace9f117792c5e62ffbe5f0be846cc064736f6c63430008110033";

type BaseConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: BaseConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class Base__factory extends ContractFactory {
  constructor(...args: BaseConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<Base> {
    return super.deploy(overrides || {}) as Promise<Base>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): Base {
    return super.attach(address) as Base;
  }
  override connect(signer: Signer): Base__factory {
    return super.connect(signer) as Base__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): BaseInterface {
    return new utils.Interface(_abi) as BaseInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): Base {
    return new Contract(address, _abi, signerOrProvider) as Base;
  }
}
