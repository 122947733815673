/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  AssetVaultLogic2,
  AssetVaultLogic2Interface,
} from "../../../../contracts/dependant/assetVault/AssetVaultLogic2";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes32[]",
        name: "tokenNames",
        type: "bytes32[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "address",
        name: "from",
        type: "address",
      },
    ],
    name: "DepositErc1155",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes32[]",
        name: "tokenNames",
        type: "bytes32[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "address",
        name: "from",
        type: "address",
      },
    ],
    name: "DepositErc20",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes32[]",
        name: "tokenNames",
        type: "bytes32[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "address",
        name: "from",
        type: "address",
      },
    ],
    name: "DepositErc721",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "tokenName",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bytes32[]",
        name: "attributeNames",
        type: "bytes32[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "uint256Values",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "bytes32[]",
        name: "bytes32Values",
        type: "bytes32[]",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "addressValues",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "bytes[]",
        name: "bytesValues",
        type: "bytes[]",
      },
    ],
    name: "MeltErc721",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes32[]",
        name: "tokenNames",
        type: "bytes32[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "traceId",
        type: "uint256",
      },
    ],
    name: "WithdrawErc1155",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "owners",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes32[]",
        name: "tokenNames",
        type: "bytes32[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "traceId",
        type: "uint256",
      },
    ],
    name: "WithdrawErc20",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "erc721TokenName",
            type: "bytes32",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            internalType: "bytes32[]",
            name: "attributeNames",
            type: "bytes32[]",
          },
          {
            internalType: "uint256[]",
            name: "uint256Values",
            type: "uint256[]",
          },
          {
            internalType: "bytes32[]",
            name: "bytes32Values",
            type: "bytes32[]",
          },
          {
            internalType: "address[]",
            name: "addressValues",
            type: "address[]",
          },
          {
            internalType: "bytes[]",
            name: "bytesValues",
            type: "bytes[]",
          },
          {
            components: [
              {
                internalType: "bytes32",
                name: "erc721TokenName",
                type: "bytes32",
              },
              {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
              },
              {
                internalType: "bytes32[]",
                name: "attributeNames",
                type: "bytes32[]",
              },
              {
                internalType: "uint256[]",
                name: "uint256Values",
                type: "uint256[]",
              },
              {
                internalType: "bytes32[]",
                name: "bytes32Values",
                type: "bytes32[]",
              },
              {
                internalType: "address[]",
                name: "addressValues",
                type: "address[]",
              },
              {
                internalType: "bytes[]",
                name: "bytesValues",
                type: "bytes[]",
              },
            ],
            internalType: "struct AssetVaultType.Erc721AffiliateParam[]",
            name: "affiliateParams",
            type: "tuple[]",
          },
        ],
        indexed: false,
        internalType: "struct AssetVaultType.Erc721Param[]",
        name: "tokens",
        type: "tuple[]",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "traceId",
        type: "uint256",
      },
    ],
    name: "WithdrawErc721",
    type: "event",
  },
  {
    inputs: [],
    name: "accessControl",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "erc1155TokenName",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "depositErc1155",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "erc1155TokenNames",
        type: "bytes32[]",
      },
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
    ],
    name: "depositErc1155s",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "onERC1155BatchReceived",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "onERC1155Received",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "erc1155TokenName",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "traceId",
        type: "uint256",
      },
    ],
    name: "withdrawErc1155",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "erc1155TokenNames",
        type: "bytes32[]",
      },
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
      {
        internalType: "uint256",
        name: "traceId",
        type: "uint256",
      },
    ],
    name: "withdrawErc1155s",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class AssetVaultLogic2__factory {
  static readonly abi = _abi;
  static createInterface(): AssetVaultLogic2Interface {
    return new utils.Interface(_abi) as AssetVaultLogic2Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): AssetVaultLogic2 {
    return new Contract(address, _abi, signerOrProvider) as AssetVaultLogic2;
  }
}
