/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  AccessControlRefLogic,
  AccessControlRefLogicInterface,
} from "../../../../contracts/dependant/accessControlRef/AccessControlRefLogic";

const _abi = [
  {
    inputs: [],
    name: "accessControl",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

const _bytecode =
  "0x6080604052348015600f57600080fd5b50608280601d6000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c806313007d5514602d575b600080fd5b600054604080516001600160a01b039092168252519081900360200190f3fea26469706673582212200d93a6f2787ee2a6de8ac7f4cae8afce5a5816655cc1026617f12ed720738b8d64736f6c63430008110033";

type AccessControlRefLogicConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: AccessControlRefLogicConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class AccessControlRefLogic__factory extends ContractFactory {
  constructor(...args: AccessControlRefLogicConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<AccessControlRefLogic> {
    return super.deploy(overrides || {}) as Promise<AccessControlRefLogic>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): AccessControlRefLogic {
    return super.attach(address) as AccessControlRefLogic;
  }
  override connect(signer: Signer): AccessControlRefLogic__factory {
    return super.connect(signer) as AccessControlRefLogic__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): AccessControlRefLogicInterface {
    return new utils.Interface(_abi) as AccessControlRefLogicInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): AccessControlRefLogic {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as AccessControlRefLogic;
  }
}
