/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  ReentrancyStorage,
  ReentrancyStorageInterface,
} from "../../../../contracts/dependant/reentrancy/ReentrancyStorage";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
] as const;

const _bytecode =
  "0x6080604052348015600f57600080fd5b50603f80601d6000396000f3fe6080604052600080fdfea26469706673582212206a90edcec10b0f59582a3cdc23f62b1b28c7c8c9bd025e22496438a81d3e1a3f64736f6c63430008110033";

type ReentrancyStorageConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: ReentrancyStorageConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class ReentrancyStorage__factory extends ContractFactory {
  constructor(...args: ReentrancyStorageConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<ReentrancyStorage> {
    return super.deploy(overrides || {}) as Promise<ReentrancyStorage>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): ReentrancyStorage {
    return super.attach(address) as ReentrancyStorage;
  }
  override connect(signer: Signer): ReentrancyStorage__factory {
    return super.connect(signer) as ReentrancyStorage__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): ReentrancyStorageInterface {
    return new utils.Interface(_abi) as ReentrancyStorageInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ReentrancyStorage {
    return new Contract(address, _abi, signerOrProvider) as ReentrancyStorage;
  }
}
