/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  ContextStorage,
  ContextStorageInterface,
} from "../../../../contracts/dependant/context/ContextStorage";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
] as const;

const _bytecode =
  "0x6080604052348015600f57600080fd5b50603f80601d6000396000f3fe6080604052600080fdfea26469706673582212208847c1e9beacd045421f3aadf69a3174bc460afcd8f520597baee87eed3eaca964736f6c63430008110033";

type ContextStorageConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: ContextStorageConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class ContextStorage__factory extends ContractFactory {
  constructor(...args: ContextStorageConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<ContextStorage> {
    return super.deploy(overrides || {}) as Promise<ContextStorage>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): ContextStorage {
    return super.attach(address) as ContextStorage;
  }
  override connect(signer: Signer): ContextStorage__factory {
    return super.connect(signer) as ContextStorage__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): ContextStorageInterface {
    return new utils.Interface(_abi) as ContextStorageInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ContextStorage {
    return new Contract(address, _abi, signerOrProvider) as ContextStorage;
  }
}
