/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  HERC165Logic,
  HERC165LogicInterface,
} from "../../../../contracts/dependant/erc165/HERC165Logic";

const _abi = [
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

const _bytecode =
  "0x608060405234801561001057600080fd5b5060d08061001f6000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c806301ffc9a714602d575b600080fd5b60576038366004606b565b6001600160e01b03191660009081526020819052604090205460ff1690565b604051901515815260200160405180910390f35b600060208284031215607c57600080fd5b81356001600160e01b031981168114609357600080fd5b939250505056fea2646970667358221220fdbd5b1b0cebdf86534c6f516189b352880f3ae5432e662ac0c6f60f5b2953f264736f6c63430008110033";

type HERC165LogicConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: HERC165LogicConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class HERC165Logic__factory extends ContractFactory {
  constructor(...args: HERC165LogicConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<HERC165Logic> {
    return super.deploy(overrides || {}) as Promise<HERC165Logic>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): HERC165Logic {
    return super.attach(address) as HERC165Logic;
  }
  override connect(signer: Signer): HERC165Logic__factory {
    return super.connect(signer) as HERC165Logic__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): HERC165LogicInterface {
    return new utils.Interface(_abi) as HERC165LogicInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): HERC165Logic {
    return new Contract(address, _abi, signerOrProvider) as HERC165Logic;
  }
}
