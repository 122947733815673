/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import {
  Signer,
  utils,
  Contract,
  ContractFactory,
  BigNumberish,
  Overrides,
} from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  HERC20Storage,
  HERC20StorageInterface,
} from "../../../../contracts/dependant/erc20/HERC20Storage";

const _abi = [
  {
    inputs: [
      {
        internalType: "string",
        name: "name_",
        type: "string",
      },
      {
        internalType: "string",
        name: "symbol_",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "cap_",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
] as const;

const _bytecode =
  "0x608060405234801561001057600080fd5b5060405161036e38038061036e83398101604081905261002f9161016d565b600361003b8482610262565b5060046100488382610262565b506005805460ff19166012179055806100a75760405162461bcd60e51b815260206004820152601560248201527f45524332304361707065643a2063617020697320300000000000000000000000604482015260640160405180910390fd5b600655506103219050565b634e487b7160e01b600052604160045260246000fd5b600082601f8301126100d957600080fd5b81516001600160401b03808211156100f3576100f36100b2565b604051601f8301601f19908116603f0116810190828211818310171561011b5761011b6100b2565b8160405283815260209250868385880101111561013757600080fd5b600091505b83821015610159578582018301518183018401529082019061013c565b600093810190920192909252949350505050565b60008060006060848603121561018257600080fd5b83516001600160401b038082111561019957600080fd5b6101a5878388016100c8565b945060208601519150808211156101bb57600080fd5b506101c8868287016100c8565b925050604084015190509250925092565b600181811c908216806101ed57607f821691505b60208210810361020d57634e487b7160e01b600052602260045260246000fd5b50919050565b601f82111561025d57600081815260208120601f850160051c8101602086101561023a5750805b601f850160051c820191505b8181101561025957828155600101610246565b5050505b505050565b81516001600160401b0381111561027b5761027b6100b2565b61028f8161028984546101d9565b84610213565b602080601f8311600181146102c457600084156102ac5750858301515b600019600386901b1c1916600185901b178555610259565b600085815260208120601f198616915b828110156102f3578886015182559484019460019091019084016102d4565b50858210156103115787850151600019600388901b60f8161c191681555b5050505050600190811b01905550565b603f8061032f6000396000f3fe6080604052600080fdfea2646970667358221220aae9940d55c008790f10da84922d235bc4ae1d7f4171514028faf821322c2e2c64736f6c63430008110033";

type HERC20StorageConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: HERC20StorageConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class HERC20Storage__factory extends ContractFactory {
  constructor(...args: HERC20StorageConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    name_: PromiseOrValue<string>,
    symbol_: PromiseOrValue<string>,
    cap_: PromiseOrValue<BigNumberish>,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<HERC20Storage> {
    return super.deploy(
      name_,
      symbol_,
      cap_,
      overrides || {}
    ) as Promise<HERC20Storage>;
  }
  override getDeployTransaction(
    name_: PromiseOrValue<string>,
    symbol_: PromiseOrValue<string>,
    cap_: PromiseOrValue<BigNumberish>,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(name_, symbol_, cap_, overrides || {});
  }
  override attach(address: string): HERC20Storage {
    return super.attach(address) as HERC20Storage;
  }
  override connect(signer: Signer): HERC20Storage__factory {
    return super.connect(signer) as HERC20Storage__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): HERC20StorageInterface {
    return new utils.Interface(_abi) as HERC20StorageInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): HERC20Storage {
    return new Contract(address, _abi, signerOrProvider) as HERC20Storage;
  }
}
