/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  Delegate,
  DelegateInterface,
} from "../../../../contracts/dependant/proxy/Delegate";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    stateMutability: "payable",
    type: "fallback",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
] as const;

const _bytecode =
  "0x608060405234801561001057600080fd5b5060f88061001f6000396000f3fe608060405236601057600e6013565b005b600e5b60aa6000604051602001604090730d0c0d0b5b595d1a1bd90b5b9bdd0b599bdd5b9960621b815260140190565b60408051601f198184030181528282528051602091820120908301520160408051601f198184030181528282528051602091820120908301520160408051601f198184030181528282528051602091820120908301520160405160208183030381529060405260ac565b565b805182801560bb578160208401fd5b8160208401f3fea26469706673582212203c7347ea4455753f1671757cbdf4b6477ec51bd421c0a8d7dc321d873365daae64736f6c63430008110033";

type DelegateConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: DelegateConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class Delegate__factory extends ContractFactory {
  constructor(...args: DelegateConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<Delegate> {
    return super.deploy(overrides || {}) as Promise<Delegate>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): Delegate {
    return super.attach(address) as Delegate;
  }
  override connect(signer: Signer): Delegate__factory {
    return super.connect(signer) as Delegate__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): DelegateInterface {
    return new utils.Interface(_abi) as DelegateInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): Delegate {
    return new Contract(address, _abi, signerOrProvider) as Delegate;
  }
}
