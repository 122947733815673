/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  OwnableStorage,
  OwnableStorageInterface,
} from "../../../../contracts/dependant/ownable/OwnableStorage";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "owner_",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
] as const;

const _bytecode =
  "0x6080604052348015600f57600080fd5b5060405160c838038060c8833981016040819052602a91604e565b600080546001600160a01b0319166001600160a01b0392909216919091179055607c565b600060208284031215605f57600080fd5b81516001600160a01b0381168114607557600080fd5b9392505050565b603f8060896000396000f3fe6080604052600080fdfea2646970667358221220fe9aa0e555910ab32efe6ffb9fce24c6f9f92a4f8721878fa93c0a87abd9c8f964736f6c63430008110033";

type OwnableStorageConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: OwnableStorageConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class OwnableStorage__factory extends ContractFactory {
  constructor(...args: OwnableStorageConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    owner_: PromiseOrValue<string>,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<OwnableStorage> {
    return super.deploy(owner_, overrides || {}) as Promise<OwnableStorage>;
  }
  override getDeployTransaction(
    owner_: PromiseOrValue<string>,
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(owner_, overrides || {});
  }
  override attach(address: string): OwnableStorage {
    return super.attach(address) as OwnableStorage;
  }
  override connect(signer: Signer): OwnableStorage__factory {
    return super.connect(signer) as OwnableStorage__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): OwnableStorageInterface {
    return new utils.Interface(_abi) as OwnableStorageInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): OwnableStorage {
    return new Contract(address, _abi, signerOrProvider) as OwnableStorage;
  }
}
