/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../../common";
import type {
  VRF,
  VRFInterface,
} from "../../../../../contracts/dependant/helperLibrary/vrfLibrary/VRF";

const _abi = [
  {
    inputs: [],
    name: "AA",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "BB",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GX",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GY",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "NN",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PP",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

const _bytecode =
  "0x61013061003a600b82828239805160001a60731461002d57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe730000000000000000000000000000000000000000301460806040526004361060655760003560e01c806303a507be14606a5780635727dc5c1460a25780637a308a4c1460a95780639870e2a41460cf578063997da8d41460e7578063eeeac01e1460ee575b600080fd5b60907f79be667ef9dcbbac55a06295ce870b07029bfcdb2dce28d959f2815b16f8179881565b60405190815260200160405180910390f35b6090600781565b60907f483ada7726a3c4655da4fbfc0e1108a8fd17b448a68554199c47d08ffb10d4b881565b609070014551231950b75fc4402da1732fc9bebe1981565b6090600081565b60906401000003d0198156fea264697066735822122096d0210eb1f061908b3304e782658138b48c9a16a33194ee7211fd7639d9412464736f6c63430008110033";

type VRFConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: VRFConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class VRF__factory extends ContractFactory {
  constructor(...args: VRFConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<VRF> {
    return super.deploy(overrides || {}) as Promise<VRF>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): VRF {
    return super.attach(address) as VRF;
  }
  override connect(signer: Signer): VRF__factory {
    return super.connect(signer) as VRF__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): VRFInterface {
    return new utils.Interface(_abi) as VRFInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): VRF {
    return new Contract(address, _abi, signerOrProvider) as VRF;
  }
}
